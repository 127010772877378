import '../../styles/containers/projects.scss';

interface ProjectCardProperties {
  thumbnail: string;
}

const ProjectCard = ({thumbnail} : ProjectCardProperties) => {
  return <div className="project-card ">
      <img className='thumbnail' src={thumbnail} alt="" />
  </div>;
};

export default ProjectCard;
