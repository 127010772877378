import { useRef } from "react";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import About from "./containers/About";
import Contact from "./containers/Contact";
import Home from "./containers/Home";
import Projects from "./containers/Projects";
import Quote from "./containers/Quote";

const App = () => {
  const containerRef = useRef(null);

  return (
    <LocomotiveScrollProvider
      options={{
        smooth: true,
        mobile: {
          breakpoint: 0,
          smooth: true,
          getDirection: true,
        },
        tablet: {
          breakpoint: 0,
          smooth: true,
          getDirection: true,
        },
        // ... all available Locomotive Scroll instance options
      }}
      watch={
        [
          //..all the dependencies you want to watch to update the scroll.
          //  Basicaly, you would want to watch page/location changes
          //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
        ]
      }
      containerRef={containerRef}
    >
      <main data-scroll-container ref={containerRef}>
        <div className="App" data-scroll-section>
          <Navbar />
          <Home />
          <Projects />
          <About />
          <Quote />
          <Contact />
          <br />
          <br />
          <br />
          <br />
          <Footer />
        </div>
      </main>
    </LocomotiveScrollProvider>
  );
};

export default App;
