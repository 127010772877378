import Button from "../components/Button";
import "../styles/containers/contact.scss";

const Contact = () => {
  return (
    <section className="section-contact">
      <h1 className="title section-title">
        Get in <br /> <strong>contact</strong>
      </h1>
      <p>
        Do you have any questions or want to work together? <br />
        Fill in the form and I will get back to you as soon as possible. <br />
        Or mail directly to  <a href="mailto:contact@happyflowsoftware.nl">contact@happyflowsoftware.nl</a>  
      </p>

      <form   action="https://formspree.io/f/mknyrgpk"
  method="POST">
        <div className="input-container">
          <label>Name</label>
          <input type="text" name="name" />

          <label>Email</label>
          <input type="email" name="email" />

          <label>Subject</label>
          <input type="text" name="subject" />

          <label className="message-area">
            Message
            <textarea cols={8}></textarea>
          </label>
        </div>
        <br />
        <Button text="Send" />
      </form>

      <div className="circle circle-5" data-scroll data-scroll-speed="2"></div>
    </section>
  );
};

export default Contact;
