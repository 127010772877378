import "../styles/containers/about.scss";
import PaulEvers from "../assets/img/Paul-Evers.jpeg";

const About = () => {
  return (
    <section className="section-about">
      <div className="circle circle-3"></div>


      <div className="center-horizontal">
        <h1 className="title section-title about-title">
          Software <br /> that makes <br /> you <strong>happy</strong>
        </h1>
      </div>
      <div className="about-container">
        <div className="grid-column">
          <p>
            Happyflow Software is a one-man software development studio run by
            me, Paul Evers. With Happyflow Software I wish to deliver high
            quality software to my customers. Services range from websites and
            mobile apps to back-end server projects.
          </p>

          <p>
            I am originally from the south of the Netherlands, but moved to
            Eindhoven to study Computer Science. Eindhoven is often described as
            the Silicon Valley of the Netherlands. It is a melting pot of many
            different technological companies. It is in this environment that I
            was able to gain the necessary skills for high-quality and modern
            software development.
          </p>
        </div>

        <div className="grid-column img-container">
          <img
            src={PaulEvers}
            alt="Photo of Paul Evers"
            className="img-profile"
            data-scroll data-scroll-speed="2"
          />
        </div>

        <div className="grid-column">
          <p>
            I have done internships at market leading companies such as Prodrive
            Technologies and iO Digital. In addition to this I have worked
            throughout my studies at different software development companies as
            well.
          </p>

          <p>
            I believe being in the field of software development, means
            constantly reinventing yourself and adapting to new technologies and
            trends. This is where my strength lays: my curiosity and
            adaptability. I apply this to the work I do for Happyflow Software
            as well.
          </p>
        </div>
      </div>


      {/* <Circle top="600px" left="-195px" radius="800px" /> */}
      <div className="circle circle-4"></div>
    </section>
  );
};

export default About;
