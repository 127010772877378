import ProjectCard from "../components/projects/ProjectCard";
import "../styles/containers/projects.scss";

import huurteam from "../assets/img/Huurteam-zuidlimburg-LOGO-01.png";
import dispersed from "../assets/img/logo-dispersed.png";
import klankbord from "../assets/img/klankbord.jpg";
import debora from "../assets/img/debora.jpg";
import { useLocomotiveScroll } from "react-locomotive-scroll";

const Projects = () => {
  const { scroll } = useLocomotiveScroll();

  const scrollTo = (section: string) => {
    const element = document.getElementsByClassName(section).item(0);
    scroll.scrollTo(element);
  };

  return (
    <section className="section-projects">
      <div className="center-horizontal">
        <h1 className="title section-title">
          <strong>Projects</strong> <br /> to be <br /> proud of
        </h1>
      </div>

      <div className="projects-container">
        <ProjectCard thumbnail={huurteam} />
        <ProjectCard thumbnail={dispersed} />
        <ProjectCard thumbnail={klankbord} />
        <ProjectCard thumbnail={debora} />

        <a
          className="onboarding-action"
          onClick={() => scrollTo("section-contact")}
        >
          <div className="project-card onboarding-card">
            <h2 className="onboarding-title title">
              <strong>Your</strong> <br /> project <br /> here?
            </h2>
            Get in <br /> touch
          </div>
        </a>
      </div>
    </section>
  );
};

export default Projects;
