interface ButtonProperties {
    text: string;
    onClick?: any;
}

const Button = ({text, onClick}: ButtonProperties) => {
  return <button className="button" onClick={onClick}>
      {text}
  </button>;
};

export default Button;
