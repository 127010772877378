import "../styles/containers/quote.scss";

const Quote = () => {
  return (
    <section className="section-quote" data-scroll data-scroll-speed="5">
      <h1 className="quote-word">Happy flow</h1>
      <h2 className="quote-info">noun [ C ]   BUSINESS, COMPUTING   specialized</h2>
      <h2 className="quote-info">UK /ˈhæp.i ˌfləʊ/ US /ˈhæp.i ˌfloʊ/</h2>
      <q>A situation in which everything happens as it is supposed to, with no faults.</q>
      <a href="https://dictionary.cambridge.org/dictionary/english/happy-path" target="_blank" rel="noopener noreferrer" className="cite">© Cambridge Dictionary, 2022</a>
    </section>
  );
};

export default Quote;
