import '../styles/home.scss';

const Home = () => {
  return (
    <section className="section-home">
      <div className="circle circle-1" data-scroll data-scroll-speed="4"></div>

      <h1 className="title line-height-130" data-scroll data-scroll-speed="6">
        A <strong>modern</strong> <br /> approach to <br /> software
      </h1>
      <h2 className='subtitle'>That will put a smile on your face</h2>

      <div className="circle circle-2" data-scroll data-scroll-speed="2"></div>
    </section>
  );
};

export default Home;
