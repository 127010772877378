import { useRef, useState } from "react";
import "../styles/navbar.scss";
import { gsap } from "gsap";
import { useLocomotiveScroll } from 'react-locomotive-scroll';

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const menuRef = useRef();
  const { scroll } = useLocomotiveScroll()

  const toggleMenu = () => {
    setIsActive(!isActive);

    !isActive ? openMenu() : closeMenu();
  };

  const openMenu = () => {
    scroll.stop();

    gsap.to(menuRef.current as any, {
      transform: "translateY(0)",
      duration: 1,
      ease: "power1",
    });
  };

  const closeMenu = () => {
    scroll.start();

    gsap.to(menuRef.current as any, {
      transform: "translateY(-100%)",
      duration: 1,
      ease: "power1",
    });
  };

  const scrollTo = (section: string) => {
    const element = document.getElementsByClassName(section).item(0);
    scroll.scrollTo(element);
  };

  const scrollToMobile = (section: string) => {
    toggleMenu();
    scrollTo(section);
  };

  return (
    <>
      <div
        className="mobile-menu"
        ref={menuRef as unknown as React.RefObject<HTMLDivElement>}
      >
        <div className="menu-items">
          <a className="menu-item" onClick={() => scrollToMobile("section-projects")}>Projects</a>
          <a className="menu-item" onClick={() => scrollToMobile("section-about")}>About</a>
          <a className="menu-item" onClick={() => scrollToMobile("section-contact")}>Contact</a>
        </div>
      </div>

      <nav>
        <div className="hamburger-container">
          <div className="brand">Happyflow Software</div>
          <div
            onClick={() => toggleMenu()}
            id="nav-icon3"
            className={isActive ? "hamburger-menu open" : "hamburger-menu"}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div className="desktop-menu">
            <div className="menu-items">
              <a className="menu-item" onClick={() => scrollTo("section-projects")}>Projects</a>
              <a className="menu-item" onClick={() => scrollTo("section-about")}>About</a>
              <a className="menu-item" onClick={() => scrollTo("section-contact")}>Contact</a>
              <div className="lang-container">
                <a className="menu-item lang-item">NL</a> | <a className="menu-item lang-item">ENG</a>
              </div>
            </div>
          </div>
          
        </div>
      </nav>
    </>
  );
};

export default Navbar;
